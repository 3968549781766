import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <circle
          cx="38"
          cy="46"
          r="30"
          strokeWidth="3"
          id="Shape"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g transform="translate(24, 30) scale(0.4)">
          <path
            id="G"
            fill="currentColor"
            d="M60.207 63.9199C56.9518 66.6868 53.3223 68.819 49.3184 70.3164C45.3145 71.7812 41.1478 72.5137 36.8184 72.5137C33.498 72.5137 30.2917 72.0742 27.1992 71.1953C24.1393 70.349 21.2747 69.1445 18.6055 67.582C15.9362 65.987 13.4948 64.099 11.2812 61.918C9.06771 59.7044 7.17969 57.263 5.61719 54.5938C4.05469 51.8919 2.83398 48.9948 1.95508 45.9023C1.10872 42.8099 0.685547 39.6035 0.685547 36.2832C0.685547 32.9629 1.10872 29.7728 1.95508 26.7129C2.83398 23.653 4.05469 20.7884 5.61719 18.1191C7.17969 15.4173 9.06771 12.9759 11.2812 10.7949C13.4948 8.58138 15.9362 6.69336 18.6055 5.13086C21.2747 3.56836 24.1393 2.36393 27.1992 1.51758C30.2917 0.638672 33.498 0.199219 36.8184 0.199219C41.1478 0.199219 45.3145 0.947917 49.3184 2.44531C53.3223 3.91016 56.9518 6.02604 60.207 8.79297L52.8828 21C50.7669 18.8516 48.3255 17.1751 45.5586 15.9707C42.7917 14.7337 39.8783 14.1152 36.8184 14.1152C33.7585 14.1152 30.8776 14.7012 28.1758 15.873C25.5065 17.0449 23.1628 18.64 21.1445 20.6582C19.1263 22.6439 17.5312 24.9876 16.3594 27.6895C15.1875 30.3587 14.6016 33.2233 14.6016 36.2832C14.6016 39.3757 15.1875 42.2728 16.3594 44.9746C17.5312 47.6764 19.1263 50.0365 21.1445 52.0547C23.1628 54.0729 25.5065 55.668 28.1758 56.8398C30.8776 58.0117 33.7585 58.5977 36.8184 58.5977C38.5762 58.5977 40.2852 58.3861 41.9453 57.9629C43.6055 57.5397 45.1842 56.9538 46.6816 56.2051V36.2832H60.207V63.9199Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
